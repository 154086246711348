import { TagData } from 'piramis-base-components/src/components/Tags/types'
import BrandTagHelpView from '@/includes/helpViews/BrandTagHelpView.vue'
import ForumTagHelpView from '../includes/helpViews/ForumTagHelpView.vue'

export const BRAND_TAG: Array<TagData> = [
    {
      text: 'Brand',
      color: 'rgb(177, 69, 250)',
      hint: BrandTagHelpView,
    }
  ]

export const FORUM_TAG: Array<TagData> = [
  {
    text: 'Forum',
    color: 'rgb(var(--a-success))',
    hint: ForumTagHelpView,
  }
]
