


















































































import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import PageTitle from '@/components/PageTitle.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { Board, Target } from '@/views/posts-planner/posts.types'
import { BRAND_TAG } from '@/includes/constants'

import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    PageTitle,
    Tags,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout
  }
})
export default class Boards extends Vue {

  BRAND_TAG = BRAND_TAG

  get boards(): Array<Board> {
    return this.$store.state.postsPlanner?.boards || []
  }

  boardRoute(routeName: string, board: Board, e?: Event): void {
    this.$router.push({
      name: routeName,
      params: {
        boardId: board.board.token_id.toString()
      },
    })
  }

  firstSevenTargets(targets: Array<Target>): Array<Target> {
    return targets.slice(0, 7).sort((a: Target, b: Target) => b.weight - a.weight)
  }
}
