




import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class BrandTagHelpView extends Vue {
  get firstChatWithAtLeastAdvancedId():number {
    return (this.$store.state.chatState.chatsInfo as any)?.chats.find((chat:any) => chat.license_type >= 1).chat_id
  }
}
